.pages-promotor .page-content{
   
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.pages-promotor .promotor-section{
    min-height: 300px;
    background-color: transparent;
    margin-top: 0px;
    margin-bottom: 10px;
}
.pages-promotor .promotor-section-1{
    background-color: #000000;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
}
.pages-promotor .promotor-section-2{
    display: flex;
    justify-content: center !important;
    column-gap: 20px;
}
.promotor-section-2{
    padding: 20px;
    display: grid !important;
    grid-template-columns: 500px 500px;
}
.promotor-section-2 .lista-promotor{
 display: flex;
 flex-direction: column;
 align-items: center;
 background-color: #00000011;
 padding-left: 20px;
 padding-right: 20px;
}
.promotor-section-2 h5{
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.promotor-section-2 button{
    margin-top: 10px;
    background-color: purple;
    color: white;
    height: 50px;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    border:none;
}
.promotor-section-0{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    background-color: purple;
    color: white;
    padding: 10px;
    margin-top: -10px;
}
.contacto {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media only screen and (max-width:420px){

    .promotor-section-2{
        padding: 0;
        grid-template-columns: 95vw ;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .lista-promotor{
       
        padding: 0px !important;
        border-radius: 10px;
        margin-top: 10px;
        padding-bottom: 10px !important;
        align-items: center !important;
        justify-content: center;
    }
    .lista-promotor .contacto{
        border:solid 2px purple !important;
        margin-top: 10px;
        width: 95% !important;
        padding: 5px;
        border-radius: 10px;
        align-items: center !important;
        justify-content: space-evenly;
    }
    .lista-promotor .contacto h5{
        width: 45% !important;
    }
    .lista-promotor .contacto button{
        width: 50% !important;
    }
    .promotor-section-0{
        flex-direction: column;
        align-items: center;
    }

}