.comp-carton{
    background-color: yellow;
    max-width: 160px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.comp-carton .carton{
    background-color: red;
    color:white;
    display: flex;
    flex-direction: column;
    
}
.comp-carton .carton-header{
    display: flex;
    justify-content: space-around;
    background-color: purple;
}
.comp-carton .carton-footer{
    display: flex;
    justify-content: center;
    background-color: purple;
}
.comp-carton .carton-tittle{
    display: flex;
    justify-content: center;
    width: 30px;
    border:solid 1px gray;
    align-items: center;
}
.comp-carton .carton-content{
    display: flex;
    flex-direction: column;
    background-color: white;
    color:black;
    
}
.comp-carton .carton-content .carton-row{
    display: flex;
    justify-content: center;
}
.comp-carton .carton-content .carton-column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
    border:solid 1px gray
}
.comp-carton .carton-header .fa-trash:hover{
    color:red;
    cursor: pointer;
}
.comp-carton.hide{
    display: none !important;
}
.comp-carton .carton-column[data-salio=true]{
    background-color: red;
    color:white
}
.comp-carton .carton-column[data-salio=false]{
    background-color: white;
    color:black
}
.carton-row-3 .carton-column-n{
    background-color: green !important;
}
.carton-row.winner .carton-column{
    background-color: green !important;
}